export const Products = [
  "Creme de Leite Lacfree 500g",
  "Creme de Leite Tradicional 500g",
  "Coalhada Seca 180g", 
  "Iogurte Kids BMM 170g",
  "Iogurte Kids Morango 170g",
  "Iogurte lacfree 140g - cranberry e gojiberry",
  "Iogurte Lacfree Morango 140g",
  "Iogurte Lacfree Tradicional 140g",
  "Iogurte Lacfree BMM 140g",
  "Iogurte Lacfree Ameixa 500g",
  "Iogurte Lacfree BMM 170g",
  "Iogurte Lacfree BMM 500g",
  "Iogurte Lacfree Coco 500g",
  "Iogurte Lacfree Goji Berry 500g",
  "Iogurte Lacfree Morango 170g",
  "Iogurte Lacfree Morango 500g",
  "Iogurte Lacfree Natural Sem Adição de Açúcar 500g",
  "Iogurte Lacfree Tradicional 170g",
  "Iogurte Lacfree Tradicional 500g",
  "Iogurte Desnatado 2 Ingredientes Lacfree 160g",
  "Iogurte Desnatado 2 Ingredientes Lacfree 480g",
  "Iogurte Desnatado Natural 140g",
  "Iogurte Integral 2 Ingredientes 160g",
  "Iogurte Integral 2 Ingredientes 480g",
  "Iogurte Whey Banana 250g",
  "Iogurte Whey Baunilha 250g",
  "Iogurte Whey Coco 250g",
  "Iogurte Whey Coco 500g",
  "Iogurte Whey Abacaxi e Coco 140g",
  "Iogurte Whey Banana e Canela 140g",
  "Iogurte Whey Frutas Vermelhas 140g",
  "Iogurte Whey Maracujá 140g",
  "Iogurte Whey Tradicional 140g",
  "Iogurte Whey Cookies & Cream 250g",
  "Iogurte Whey Cookies & Cream 500g",
  "Iogurte Whey Goji Berry 170g",
  "Iogurte Whey Doce de Leite 250g",
  "Iogurte Whey Jabuticaba 250g",
  "Iogurte Whey Morango 250g",
  "Iogurte Whey Morango 250g",
  "Iogurte Whey Morango 500g",
  "Iogurte Whey Pasta de Amendoim 250g",
  "Iogurte Whey Pêssego 170g",
  "Iogurte Whey Torta de Limão 250g",
  "Iogurte Whey Tradicional 500g",
  "Iogurte Probiótico Ameixa Triplo Zero Verde Campo 500g",
  "Iogurte Probiótico Ameixa 500g",
  "Iogurte Probiótico BMM 170g",
  "Iogurte Probiótico Coco 500g",
  "Iogurte Probiótico BMM 500g",
  "Iogurte Probiótico Triplo Zero Morango 500g",
  "Iogurte Probiótico Morango 170g",
  "Iogurte Probiótico Morango 500g",
  "Iogurte Probiótico Tradicional 500g",
  "Kefir Lacfree Morango 500g",
  "Kefir Lacfree Tradicional 500g",
  "Queijo Frescal Tradicional 450g",
  "Queijo Frescal Lacfree 250g",
  "Queijo Frescal Light 450g",
  "Queijo Padrão Light 450g",
  "Queijo Padrão Lacfree 450g",
  "Queijo Padrão 450g",
  "Queijo Mussarela Lacfree 150g",
  "Queijo Mussarela Lacfree 500g",
  "Queijo tipo cottage lacfree 200g",
  "Queijo Cottage Lacfree 400g",
  "Queijo Cottage Tradicional 200g",
  "Queijo Cottage Tradicional 400g",
  "Requeijão Lacfree Tradicional 180g",
  "Requeijão Lacfree Tradicional 400g",
  "Shake Whey Baunilha 250ml",
  "Shake Whey Cappuccino 250ml",
  "Shake Whey Caramelo 250ml",
  "Shake Whey Chocolate 250ml",
  "Sobremesa Banana e Canela 200g",
  "Sobremesa Frutas Vermelhas 200g"
]